<template>
    <div>
        <b-card>
            <b-row>
                <b-col sm="12">
                    <b-row class="mt-1">
                        <b-col sm="4" xl="3" md="3">
                            <label>TYPE MESSAGE</label>
                            <!-- <b-form-input
                id="type-message"
                v-model="initFilter.type_message"
                placeholder="type message"
                type="text"
                class="d-inline-block"
              /> -->
                            <v-select v-model="initFilter.type_message" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="type_message_opt" />
                        </b-col>
                        <b-col sm="4" xl="2" md="2">
                            <label>TTAAii</label>
                            <b-form-input id="ttaaii" v-model="initFilter.ttaaii" placeholder="ttaaii" type="text" class="d-inline-block" style="height: 39px;" />
                        </b-col>
                        <b-col sm="4" xl="2" md="2">
                            <label>CCCC</label>
                            <b-form-input id="cccc" v-model="initFilter.cccc" placeholder="cccc" type="text" class="d-inline-block" style="height: 39px;" />
                        </b-col>
                        <b-col sm="4" xl="2" md="2">
                            <label>WMOID</label>
                            <b-form-input id="wmoid" v-model="initFilter.wmo_id" placeholder="wmo id" type="text" class="d-inline-block" style="height: 39px;" />
                        </b-col>
                        <b-col sm="4" xl="2" md="2">
                            <label>Tanggal Awal-Akhir</label>
                            <flat-pickr v-model="initFilter.rangeDate" style="height: 40px;" class="form-control" :config="{ mode: 'range' }" />
                        </b-col>
                        <b-col sm="1 pad1" style="margin-top: 25px;">
                            <b-button variant="relief-primary" v-on:click="onFilter"> Filter </b-button>
                        </b-col>
                    </b-row>

                    <b-row class="mt-1">
                        <b-col sm="4" xl="2" md="2">
                            <!-- <label>TYPE MESSAGE</label>
              <v-select v-model="initFilter.type_message" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="type_message_opt"  /> -->
                        </b-col>
                        <b-col sm="4" xl="2" md="2">
                            <!-- <label>TTAAii</label>
              <b-form-input
                id="ttaaii"
                v-model="initFilter.ttaaii"
                placeholder="ttaaii"
                type="text"
                class="d-inline-block"
                style="height: 39px"
              /> -->
                        </b-col>
                        <b-col sm="4" xl="2" md="2">
                            <!-- <label>CCCC</label>
              <b-form-input
                id="cccc"
                v-model="initFilter.cccc"
                placeholder="cccc"
                type="text"
                class="d-inline-block"
                style="height: 39px"
              /> -->
                        </b-col>
                        <b-col sm="4" xl="2" md="2">
                            <!-- <label>WMOID</label>
              <b-form-input
                id="wmoid"
                v-model="initFilter.wmo_id"
                placeholder="wmo id"
                type="text"
                class="d-inline-block"
                style="height: 39px"
              /> -->
                        </b-col>
                        <b-col sm="4" xl="3" md="3">
                            <b-row>
                                <b-col>
                                    <b-form-group label-size="sm" label="Jam Awal" label-for="input-jam">
                                        <a-select show-search :filter-option="true" :not-found-content="null" style="width: 100%;" :value="initFilter.jam_awal" @change="(value) => (initFilter.jam_awal = value)" id="input-jam">
                                            <!-- <a-select-option value="">-</a-select-option> -->
                                            <a-select-option value="00:00">00:00</a-select-option>
                                            <a-select-option value="01:00">01:00</a-select-option>
                                            <a-select-option value="02:00">02:00</a-select-option>
                                            <a-select-option value="03:00">03:00</a-select-option>
                                            <a-select-option value="04:00">04:00</a-select-option>
                                            <a-select-option value="05:00">05:00</a-select-option>
                                            <a-select-option value="06:00">06:00</a-select-option>
                                            <a-select-option value="07:00">07:00</a-select-option>
                                            <a-select-option value="08:00">08:00</a-select-option>
                                            <a-select-option value="09:00">09:00</a-select-option>
                                            <a-select-option value="10:00">10:00</a-select-option>
                                            <a-select-option value="11:00">11:00</a-select-option>
                                            <a-select-option value="12:00">12:00</a-select-option>
                                            <a-select-option value="13:00">13:00</a-select-option>
                                            <a-select-option value="14:00">14:00</a-select-option>
                                            <a-select-option value="15:00">15:00</a-select-option>
                                            <a-select-option value="16:00">16:00</a-select-option>
                                            <a-select-option value="17:00">17:00</a-select-option>
                                            <a-select-option value="18:00">18:00</a-select-option>
                                            <a-select-option value="19:00">19:00</a-select-option>
                                            <a-select-option value="20:00">20:00</a-select-option>
                                            <a-select-option value="21:00">21:00</a-select-option>
                                            <a-select-option value="22:00">22:00</a-select-option>
                                            <a-select-option value="23:00">23:00</a-select-option>
                                        </a-select>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label-size="sm" label="Jam Akhir" label-for="input-jam">
                                        <a-select show-search :filter-option="true" :not-found-content="null" style="width: 100%;" :value="initFilter.jam_akhir" @change="(value) => (initFilter.jam_akhir = value)" id="input-jam">
                                            <!-- <a-select-option value="">-</a-select-option> -->
                                            <a-select-option value="00:00">00:00</a-select-option>
                                            <a-select-option value="01:00">01:00</a-select-option>
                                            <a-select-option value="02:00">02:00</a-select-option>
                                            <a-select-option value="03:00">03:00</a-select-option>
                                            <a-select-option value="04:00">04:00</a-select-option>
                                            <a-select-option value="05:00">05:00</a-select-option>
                                            <a-select-option value="06:00">06:00</a-select-option>
                                            <a-select-option value="07:00">07:00</a-select-option>
                                            <a-select-option value="08:00">08:00</a-select-option>
                                            <a-select-option value="09:00">09:00</a-select-option>
                                            <a-select-option value="10:00">10:00</a-select-option>
                                            <a-select-option value="11:00">11:00</a-select-option>
                                            <a-select-option value="12:00">12:00</a-select-option>
                                            <a-select-option value="13:00">13:00</a-select-option>
                                            <a-select-option value="14:00">14:00</a-select-option>
                                            <a-select-option value="15:00">15:00</a-select-option>
                                            <a-select-option value="16:00">16:00</a-select-option>
                                            <a-select-option value="17:00">17:00</a-select-option>
                                            <a-select-option value="18:00">18:00</a-select-option>
                                            <a-select-option value="19:00">19:00</a-select-option>
                                            <a-select-option value="20:00">20:00</a-select-option>
                                            <a-select-option value="21:00">21:00</a-select-option>
                                            <a-select-option value="22:00">22:00</a-select-option>
                                            <a-select-option value="23:00">23:00</a-select-option>
                                        </a-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="1 pad1" style="margin-top: 25px;">
                            <!-- <b-button  variant="relief-primary" v-on:click="onFilter"> Filter </b-button> -->
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>

        <b-card v-if="isCardTableActive">
            <b-row>
                <b-col md="2" sm="4" class="my-1">
                    <b-form-group class="mb-0">
                        <label class="d-inline-block text-sm-left mr-50">Per page</label>
                        <b-form-select id="perPageSelect" v-model="perPage" size="md" :options="pageOptions" class="w-50" />
                    </b-form-group>
                </b-col>
                <b-col md="2" sm="4" class="my-1">
                    <b-form-group class="mb-0">
                        <label class="d-inline-block text-sm-left mr-50">Sort Date</label>
                        <b-form-select id="sortDate" v-model="sortDate" size="md" :options="sortDateOpt" class="w-50" />
                    </b-form-group>
                </b-col>
                <b-col md="4" sm="4" class="my-1"></b-col>
                <b-col md="4" class="my-1">
                    <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="md" label-for="filterInput" class="mb-0">
                        <b-input-group size="md">
                            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>

                <!-- <b-col md="3" class="my-1">
          <div v-if="pibaldataform" class="float-right">
            <download-excel class="btn btn-sm btn-success mr-1" :data="csv_data_pibal" type="csv" :name="datapibalname"
              header="Data Pibal" title="Data Pibal" :escapeCsv="escapeCsv"> Data Pibal </download-excel>
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-export-pdf-datapibal
              variant="outline-primary" size="sm" style="float-right; margin-right: 2px"> Data Pibal PDF </b-button>
          </div>
        </b-col>
        <b-col md="3" class="my-1" v-if="pibaldataform">
          <download-excel class="btn btn-sm btn-success mr-1" :data="csv_raw_pibal" type="csv" :name="rawpibalname"
            header="Raw Pibal" title="Raw Pibal" :escapeCsv="escapeCsv"> Raw Pibal </download-excel>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-export-pdf-rawpibal
            variant="outline-primary" size="sm" style="float-right; margin-right: 2px"> Raw Pibal PDF </b-button>
        </b-col>
        <b-col md="3" class="my-1" v-if="ready_export && !pibaldataform">
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-export-pdf variant="outline-primary"
            size="sm" style="float-right; margin-right: 2px"> Export PDF </b-button>
          <download-excel class="btn btn-sm btn-success ml-1" :data="items" type="csv" :name="csvfilename"
            :escapeCsv="escapeCsv" :header="form_name_exp_xls" :title="form_name_exp_xls"> Download CSV </download-excel>
        </b-col> -->

                <b-col cols="12">
                    <b-table
                        class="table b-table my-table table-striped table-hover table-md table-responsive text-nowrap text-center"
                        striped
                        responsive
                        :bordered="true"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="items"
                        :fields="fields"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        show-empty
                        empty-text="Data tidak tersedia untuk filter terpilih"
                    >
                        <template #cell(show_details)="row">
                            <b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails">
                                {{ row.detailsShowing ? "Hide" : "Show" }}
                            </b-form-checkbox>
                        </template>

                        <template #cell(type_message)="row">
                            <!-- <span>{{new Date(row.item.timestamp_data).toUTCString()}}</span> -->
                            <span class="font-weight-bolder">{{ typeMessageToString(row.item.type_message) }}</span>
                        </template>

                        <template #cell(station_wmo_id)="row">
                            <!-- <span>{{new Date(row.item.timestamp_data).toUTCString()}}</span> -->
                            <span class="font-weight-bolder">{{ row.item.station_wmo_id }}</span>
                        </template>

                        <template #cell(timestamp_data)="row">
                            <!-- <span>{{new Date(row.item.timestamp_data).toUTCString()}}</span> -->
                            <span class="font-weight-bolder">{{ formatDate(new Date(row.item.timestamp_data)) }}</span>
                        </template>

                        <template #cell(timestamp_sent_data)="row">
                            <!-- <span>{{new Date(row.item.timestamp_sent_data).toUTCString()}}</span> -->
                            <span class="font-weight-bolder">{{ formatDate(new Date(row.item.timestamp_sent_data)) }}</span>
                        </template>

                        <template #row-details="row">
                            <b-row class="mb-0 ml-0 mr-0">
                                <!-- <b-col md="1" sm="1" class="mb-0" >
                    <strong>Sandi : </strong>
                  </b-col> -->
                                <b-col md="12" sm="12" class="mb-0 ml-0 mr-0">
                                    <b-form-textarea rows="4" id="textarea-sandi" placeholder="Sandi" readonly 
                                    v-model="row.item.sandi_gts" style="background-color: #fff;" 
                                    class="font-weight-bolder border-0 shadow-none rounded-0"></b-form-textarea>
                                    <!-- {{ row.item.sandi_gts }} -->
                                </b-col>
                            </b-row>
                        </template>
                        <!-- <template #cell(encoded_synop)="row">
              <div class="w-100 text-left pl-1">{{ row.item.encoded_synop }}</div>
            </template>
            <template #cell(qc_parameter)="row">
              <div class="w-100 text-left pl-1">{{ row.item.qc_parameter }}</div>
            </template>
            <template #cell(sandi_pibal)="row">
              <div class="w-50 text-left pl-1">{{ row.item.sandi_pibal }}</div>
            </template>
            <template #cell(azimuth)="row">
              <div class="w-50 text-left pl-1">{{ row.item.azimuth }}</div>
            </template>
            <template #cell(elevasi)="row">
              <div class="w-50text-left pl-1">{{ row.item.elevasi }}</div>
            </template>
            <template #cell(lapisan)="row">
              <div class="w-50 text-left pl-1">{{ row.item.lapisan }}</div>
            </template> -->
                    </b-table>
                </b-col>

                <b-col cols="12">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="md" class="my-1" />
                </b-col>
            </b-row>
        </b-card>
        <!-- <div id="divExport" class="divExport">
      <table>
        <tr>
          <td v-for="item in items" :key="item"></td>
        </tr>
      </table>
    </div> -->
        <!-- <client-only>
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
        paginate-elements-by-height="1400" :filename="form_name_exp_xls" :pdf-quality="2" pdf-content-width="1800px"
        :manual-pagination="false" pdf-format="legal" :pdf-orientation="EXPORT_ORIENTATION"
        @beforeDownload="beforeDownload($event)" @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()" @hasGenerated="hasGenerated($event)" ref="html2Pdf">
        <section slot="pdf-content">
          <div v-if="PDF_SELECTED == 'PIAS'">
            <PiasPdf :dataPDF="STORE_EXPORT_PIAS" :pejabat_id="pejabat_id" :pejabat_nama="pejabat_nama"
              :dislaimer="dislaimer" :ttd_digital="ttd_digital" :form_name_exp_pdf="form_name_exp_pdf"></PiasPdf>
          </div>
        </section>
      </vue-html2pdf>
    </client-only> -->
        <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
    </div>
</template>

<script>
//import LiquorTree from "liquor-tree";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import PiasPdf from "./pdf/PiasPdf.vue";
import {
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroupAppend,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BButton,
    BButtonGroup,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BPopover,
    BModal,
    VBModal,
    BForm,
    AlertPlugin,
    VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import apiRequest from "@/api/outputextractgts";
import VueHtml2pdf from "vue-html2pdf";

import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import helper from "@/api/helper";
import { forEach } from "postcss-rtl/lib/affected-props";

export default {
    components: {
        AlertPlugin,
        BOverlay,
        PiasPdf,
        VueHtml2pdf,
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        vSelect,
        // jsPDF,
        // JsPDFAutotable,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BFormSelect,
        BButton,
        BButtonGroup,
        flatPickr,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormDatepicker,
        BTable,
        BAvatar,
        BBadge,
        BPagination,
        BInputGroupAppend,
        BPopover,
        BFormTextarea,
        BModal,
        BForm,
        VBTooltip,
    },
    directives: {
        "b-modal": VBModal,
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            isCardTableActive: true,
            initFilter: {
                type_message: 0,
                ttaaii: "",
                cccc: "",
                wmo_id: "",
                rangeDate: "",
                jam_awal: "",
                jam_akhir: "",
            },
            type_message_opt: [
                { value: "1", text: "SINOPTIK" },
                { value: "56", text: "SHIP" },
                { value: "2", text: "PILOT" },
                { value: "3", text: "TEMP" },
                { value: "4", text: "METAR" },
                { value: "5", text: "SPECI" },
                { value: "6", text: "AERODROME (VT ≥ 12 hours) (TAF)" },
                { value: "31", text: "TROPICAL CYCLONE ADVISORIES" },
                { value: "38", text: "AVIATION ROUTE (ROFOR)" },
                { value: "41", text: "VOLCANIC ASH ADVISORIES" },
                // { value: "6", text: "FORECAST" },
                { value: "7", text: "CLIMAT" },
                { value: "8", text: "WXREV" },
                { value: "9", text: "CLIMAT TEMP" },
                { value: "10", text: "SEISMIC DATA" },
                // { value: "11", text: "WARNING" },
                // { value: "12", text: "AIRCRAFT REPORT" },
                { value: "39", text: "WARNING AIRMET" },
                { value: "11", text: "WARNING TSUNAMI" },
                { value: "12", text: "WARNING SIGMET" },
                { value: "13", text: "WARNING TROPICAL CYCLONE (Typhoon/hurricane)" },
                { value: "40", text: "WARNING TROPICAL CYCLONE (SIGMET)" },
                { value: "47", text: "WARNING VOLCANIC ASH CLOUDS (SIGMET)" },
                { value: "44", text: "WARNING OTHER" },
                { value: "49", text: "AIRCRAFT REPORT (CODAR/AIREP)" },
                // { value: "50", text: "AIRCRAFT REPORT (AMDAR)" },
                { value: "53", text: "DATA CUACA HARIAN" },
                { value: "54", text: "DATA RATA RATA" },
                { value: "55", text: "DATA CUACA EKSTREM" }
            ],
            perPage: 20,
            pageOptions: [5, 10, 20, 50],
            sortDate: "Asc",
            sortDateOpt: ["Asc", "Desc"],
            totalRows: 1,
            currentPage: 1,
            filter: null,
            filterOn: [],
            fields: [
                { key: "type_message", label: "TYPE MESSAGE" },
                { key: "station_wmo_id", label: "WMO ID" },
                { key: "timestamp_data", label: "Timestamp Data" },
                { key: "timestamp_sent_data", label: "Received Data" },
            ],
            items: [],
            showLoading: false
        };
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
        },
    },
    mounted() {
        this.totalRows = this.items.length;
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        async onFilter() {
            // console.log("onFilter", this.initFilter);
            let dataRequest = {};
            if (this.initFilter.type_message != 0 && this.initFilter.type_message != "0" && this.initFilter.type_message) {
                dataRequest["type_message"] = this.initFilter.type_message.value;
            }
            if (this.initFilter.ttaaii != 0 && this.initFilter.ttaaii != "0" && this.initFilter.ttaaii) {
                dataRequest["ttaaii"] = this.initFilter.ttaaii;
            }
            if (this.initFilter.cccc != 0 && this.initFilter.cccc != "0" && this.initFilter.cccc) {
                dataRequest["cccc"] = this.initFilter.cccc;
            }
            if (this.initFilter.wmo_id != 0 && this.initFilter.wmo_id != "0" && this.initFilter.wmo_id) {
                dataRequest["station_wmo_id"] = this.initFilter.wmo_id;
            }
            if (this.initFilter.rangeDate != 0 && this.initFilter.rangeDate != "0" && this.initFilter.rangeDate) {
                let arrDate = this.initFilter.rangeDate.split(" to ");
                // console.log("arrDate", arrDate);
                if (this.initFilter.jam_awal && this.initFilter.jam_awal != "") {
                    dataRequest["timestamp_data__gte"] = arrDate[0] + "T" + this.initFilter.jam_awal;
                } else {
                    dataRequest["timestamp_data__gte"] = arrDate[0] + "T00:00";
                }

                if (this.initFilter.jam_akhir && this.initFilter.jam_akhir != "") {
                    dataRequest["timestamp_data__lte"] = arrDate.length > 1 ? arrDate[1] : arrDate[0] + "T" + this.initFilter.jam_akhir;
                } else {
                    dataRequest["timestamp_data__lte"] = arrDate.length > 1 ? arrDate[1] : arrDate[0] + "T23:59";
                }
            }
            this.showLoading = true;
            try {
                const { data } = await apiRequest.getExtractGts(dataRequest);
                // console.log('data', data)
                if (data) {
                    // console.log("onFilter request", data);
                    // this.items = data.items
                    this.items = [];
                    for (var item of data.items) {
                        
                        // AN prettify sandi pibal dan rason
                        if (item.type_message == '3' || item.type_message == '2') { // pibal dan rason
                            item = this.prettifyPilotRason(item)
                        }

                        // for(item of data.items) {
                        item["_showDetails"] = true;
                        this.items.push(item);
                        // console.log("item"), item;
                    }
                    this.showLoading = false;
                }
                this.totalRows = this.items.length;
                // console.log("onFilter showDetails", this.items);
            } catch (error) {
                console.log(error);
                this.showLoading = false;
            }
        },

        async findStationbyWmoId(wmoid) {},

        typeMessageToString(type) {
            for (var key in this.type_message_opt) {
                var obj = this.type_message_opt[key];
                if (obj.value == type) {
                    return obj.text;
                }
            }
        },

        padTo2Digits(num) {
            return num.toString().padStart(2, "0");
        },

        formatDate(date) {
            return (
                [this.padTo2Digits(date.getDate()), this.padTo2Digits(date.getMonth() + 1), date.getFullYear()].join("-") +
                " " +
                [this.padTo2Digits(date.getHours()), this.padTo2Digits(date.getMinutes()), this.padTo2Digits(date.getSeconds())].join(":") +
                " UTC"
            );
        },

        prettifyPilotRason(item) {
            item["sandi_gts"] = item.sandi_gts?.replace(/=([^\n\r])/g, '=\n\n$1');

            // Adding a final check to ensure the last '=' doesn't get a newline.
            if (!item["sandi_gts"].endsWith('=')) {
                item["sandi_gts"] = item["sandi_gts"].replace(/=\n\r\n\r$/, '=');
            }

            return item
        }
    },
    watch: {
        sortDate(val) {
            if (val == "Desc") {
                var data = this.items;
                // data.sort(function (a, b) {
                //   return new Date(b.data_timestamp) - new Date(a.data_timestamp) ;
                // });
                data.sort((a, b) => {
                    // var station_name = a.station_wmo_id.localeCompare(b.station_wmo_id);
                    var station_name = a.station_wmo_id - b.station_wmo_id;
                    var datestam = new Date(a.timestamp_data) - new Date(b.timestamp_data);
                    return station_name || -datestam;
                });
                this.items = data;
            } else if (val == "Asc") {
                var data = this.items;
                // data.sort(function (a, b) {
                //   return new Date(a.data_timestamp) - new Date(b.data_timestamp);
                // });
                data.sort((a, b) => {
                    // var station_name = a.station_wmo_id.localeCompare(b.station_wmo_id);
                    var station_name = b.station_wmo_id - a.station_wmo_id;
                    var datestam = new Date(b.timestamp_data) - new Date(a.timestamp_data);
                    return station_name || -datestam;
                });
                this.items = data;
            }
        },
    },
    onClosePopup2() {
        this.popoverShow2 = false;
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
.pad1 {
    padding-right: 0px;
}

.pad2 {
    padding-right: 0px;
    padding-left: 0px;
}
</style>

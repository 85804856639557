<template>
  <div>
    <div class="fw-bolder text-dark pt-1" style="margin-left: 650px"> {{ form_name_exp_pdf.text }}</div>
    <div v-if="dataPDF.length > 0">
      <b-row>
        <b-col cols="12">
          <b-table :items="dataPDF" :fields="fields" class="export_table">
            <template #cell(data_timestamp)="data">
              {{ data.value }}
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1"> </b-col>
        <b-col cols="2" style="font-size: 8px bold">
          <img :src="ttd_digital" style="width: 80px; height: 40px" /><br />
          {{ pejabat_nama }}
        </b-col>
        <b-col cols="4" style="font-size: 7.5px; border: 1px solid #666666">
          {{ dislaimer }}
        </b-col>
      </b-row>
    </div>
    <div v-if="dataPDF.length == 0">No data</div>
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BPagination,
  BInputGroupAppend,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BInputGroup,
  BFormSelect,
  BButton,
  BButtonGroup,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormGroup,
} from "bootstrap-vue";

import axios from "axios";
import jwt from "@/auth/jwt/useJwt";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BButton,
    BButtonGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormDatepicker,
    BTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroupAppend,
  },
  name: "Export Data",
  props: ["dataPDF", "pejabat_id", "pejabat_nama", "dislaimer", "ttd_digital", "form_name_exp_pdf"],
  //   : Array,
  //   pejabat_id,
  //   pejabat_nama
  // },
  data: function () {
    return {
      fields: [],
      dataImg: "",
    };
  },
  mounted() {
    // this.getEsign();
    console.log(this.dislaimer);
    console.log("pejabat id : ", this.pejabat_id);
  },
  methods: {
    getEsign() {
      //   this.dataImg = "";
      var token = jwt.getToken();
      var config = {
        method: "get",
        url: process.env.VUE_APP_BASE_URL+"/1/1/20/1/metaphoto/111_1/@download/image",
        headers: {
          Authorization: `Bearer  ${token}`,
        },
        responseType: "arraybuffer",
      };

      axios(config)
        .then((response) => {
          this.dataImg = Buffer.from(response.data, "base64"); // response.data
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    testCall() {
      console.log("test Call ok");
    },
  },
  watch: {
    pejabat_id(newval, oldval) {
      // this.getEsign();
      // this.testCall();
      // console.log("new : "+newval+ " -  old : "+oldval)
    },
  },
  computed: {
    // data(){
    //     //console.log(this.data)

    //     return this.dataPDF.JSONstingify()
    // }
    count_data() {
      var count = this.data.length;
      return count;
    },
    dataUrl() {
      // var image = 'data:image/jpeg;base64,' + btoa(response.dthis.dataImg);
      // return image
      return "data:image/jpeg;base64," + btoa(new Uint8Array(this.dataImg).reduce((data, byte) => data + String.fromCharCode(byte), ""));
    },
  },
};
</script>
<style>
.export_table {
  width: 1303px !important;
  margin: 20px 20px 20px 20px;
}
.export_table thead tr th {
  padding: 3px !important;
  font-size: 7.5px !important;
  color: rgb(14, 14, 14) !important;
}
.export_table tbody tr td {
  padding: 3px !important;
  font-size: 7.5px !important;
  color: black !important;
}
.export_img {
  width: 100px !important;
  height: 100px !important;
}
</style>
